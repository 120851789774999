import React from "react";
import {Link} from "gatsby";
import {Trans} from "@lingui/macro";
import {Container, Row} from "reactstrap";
import "./LiveVideo.css";
import userIcon from "../../static/images/account_circle-24px.svg";

const LiveVideo = ({video}) => {

    const duration = !video ? 0 : Math.floor(video.json.duration / 60) + ':' + (video.json.duration % 60);
    var d = !video  ? new Date() : new Date(video.publication_date);

    return (!video ? '' : (
        <div className="SideBarBox LiveVideo">
            <Container>
                <Row>
                    <div className="order-lg-1 order-md-2 order-sm-2 order-2 col-lg-6 col-md-12 col-sm-12">
                        <h2><Link to={"/sk/video/" + video.id}>{video.title}</Link></h2>
                        <p className="Credits">
                            <span className="Item"><img className="icon" src={userIcon} alt="Autor"/> {video.author}</span>
                            <span
                                className="Item"><strong><Trans>Dátum</Trans></strong>: {d.toLocaleDateString('sk')}</span>
                            <span className="action"><strong><Trans>Počet videní</Trans></strong>: {video.json.stats.plays}</span>
                        </p>
                        <p className="description">{video.description}</p>
                        <p className="action"><Link className="WatchNowBtn" to={"/sk/video/" + video.id}><Trans>Detail</Trans></Link></p>
                    </div>
                    <div className="order-lg-2 order-md-1 order-sm-1 order-1 col-lg-6 col-md-12 col-sm-12">
                        <div className="Cinema">
                            <div className="VideoHolder">
                                <Link to={"/sk/video/" + video.id}>
                                    <img src={video.json.pictures.sizes[0].link} alt={video.title}/>
                                    <span className="length">{duration}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    ));
};

export default LiveVideo;